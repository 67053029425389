import postPic1 from '../Assets/postpic1.png'
import postPic2 from '../Assets/postpic2.jpg'
import postPic3 from '../Assets/postpic3.jpg'
import postPic4 from '../Assets/IMG_20240207_012653.jpg'
import postPic5 from '../Assets/IMG_20240207_012714.jpg'


export const PostsData = [
    {
        img: postPic1,
        name: 'Shelendra Tomar',
        desc: "Jai Gange Maa",
        likes: 2300,
        liked: true
    },
    {
        img: postPic2,
        name: 'Manish Chandel',
        desc: "Party time :)",
        likes: 2300,
        liked: false

    },
    {
        img:postPic3,
        name: "Jeevesh Singh",
        desc: "Time Pass",
        likes: 800,
        liked: false
    },
    {
        img:postPic4,
        name: "Jeevesh Singh",
        desc: "Road Trip",
        likes: 800,
        liked: false
    },
    {
        img:postPic5,
        name: "Jeevesh Singh",
        desc: "Mountains",
        likes: 800,
        liked: true
    }
]