import img1 from "../Assets/img1.jpg";
import img2 from "../Assets/img2.png";
import img3 from "../Assets/img3.png";
import img4 from "../Assets/img4.jpg";
import img5 from "../Assets/img5.jpg";

export const Followers = [
  { name: "Shelendra Tomar", username: "stomar", img: img1 },
  { name: "Sandhya Dubey", username: "sandhyadubey", img: img2 },
  { name: "Manish Chandel", username: "manishthakur", img: img3 },
  { name: "Lokesh Dubey", username: "lokesh_dubey", img: img4 },
  { name: "Jeevesh Singh", username: "okis", img: img5 },
];
