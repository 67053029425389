export const TrendData= [
  {
    name: "NarendraModi",
    shares: 97,
  },
  {
    name: "IndiaWins",
    shares: 80.5,
  },
  {
    name: "RamMandir",
    shares: 75.5,
  },
  {
    name: "Reactjs",
    shares: 72,
  },
  {
    name: "Uttarpradesh",
    shares: 71.9,
  },
  {
    name: "ViratKohli",
    shares: 20,
  },
];
